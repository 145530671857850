import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faCode, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

export default function Job() {
  return (
    <Layout>
      <SEO
          title="Senior Software Engineer - Compute Software"
          description="Be one of the earliest employees and join our early-stage engineering team as a Senior Software Engineer."/>
      <Helmet></Helmet>
      <section className="job-posting sub-general-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="job-title">Senior Software Engineer</h1>
            </div>
          </div>
          <div className="job-meta row">
            <div className="col-lg-12">
                <div className="job-location meta-item">
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" /><span className="location">Remote</span>
                </div>
                <div className="job-hours meta-item">
                  <FontAwesomeIcon icon={faClock} size="2x" /><span className="hours">Full-time</span>
                </div>
                <div className="job-skills meta-item">
                  <FontAwesomeIcon icon={faCode} size="2x" /><span className="skills">Clojure / ClojureScript required</span>
                </div>
            </div>
          </div>
          <div className="job-details row">
            <div className="perk col-lg-12 col-md-12 col-sm-12">
              <h3>About Us</h3>
              <p>With ever-growing workloads on the cloud, companies face significant challenges in managing productivity and spending, and maximizing impact to their businesses. Compute Software is addressing a huge market opportunity to help companies make good business decisions and run optimally on the public cloud. We're building a powerful platform to give customers the tools they need to gain transparency, optimize their usage, and execute change across their organizations.</p>
              <p>We're a small, distributed team, currently spanning California to Quebec, and we offer early stage market-rate compensation (including equity), health and dental insurance, and 401K benefits. You'll be joining a venture capital-backed, distributed team with ambitious goals, and you will have the ability to make a direct and lasting impact on the team and product.</p>

              <h3>Your Role</h3>
              <p>Be one of the earliest employees and join our early-stage engineering team as a Senior Software Engineer. You will be essential in shaping the features and functionality of our SaaS platform, culture and processes.</p>
              <p>You'll spend your day enveloped in Clojure. The backend is written in Clojure and driven by data in Datomic and InfluxDB. The frontend is written in ClojureScript using <a href="https://github.com/day8/re-frame" target="_blank" rel="noreferrer">re-frame</a> and communicates with the backend using <a href="https://github.com/wilkerlucio/pathom" target="_blank" rel="noreferrer">Pathom</a>. We deploy to AWS Fargate and Datomic Ions.</p>
              <p>For product development, we follow <a href="https://basecamp.com/shapeup" target="_blank" rel="noreferrer">Shape Up</a>. We use Notion, Slack, and ClubHouse.</p>

              <h3>What Will You do at Compute Software?</h3>
              <ul>
                <li>Write Clojure and ClojureScript.</li>
                <li>Design, build, and deploy features and bug fixes across the entire stack.</li>
                <li>Become an expert in all the nuances of the various cloud platforms like Amazon Web Services, Google Cloud, and Microsoft Azure.</li>
                <li>Provide product feedback and evaluate trade-offs to impact product direction.</li>
                <li>Debug production problems.</li>
              </ul>

              <h3>What We’re Looking For</h3>
              <p><strong>Passion</strong> - you are excited about the large, high-growth cloud computing market and figuring out how to help customers, who are using cloud computing solutions today. You are excited by being one of the earliest employees and getting to work with a small team.</p>
              <p><strong>Engineering experience</strong> - you're a Clojure practitioner with 6+ years of professional experience. You know what it takes to create large-scale b2b software. You can create effective and simple solutions to problems quickly, and communicate your ideas clearly to your teammates.</p>
              <p><strong>Product-minded</strong> - you love building products and you care about the details of creating a great user experience. You have an interest in how users will use our platform and the impact we will have on them. You can balance your consideration of the product and user requirements with technical complexity and implementation details to make appropriate decisions when things are unclear.</p>
              <p><strong>Effective communication</strong> - you're great at communicating. If something is unclear you reach out and ask questions. You're comfortable owning, communicating and presenting information on specific projects or initiatives, both in writing and in person.</p>
              <p><strong>Organizational and project management</strong> - you are highly organized and able to self-manage projects in a fast-moving company. You are able to take high level goals and break them down into achievable steps.</p>

              <h3>Please Send Us</h3>
              <ul>
                <li>Your resume / LinkedIn profile</li>
                <li>Link to Github projects or other work samples</li>
              </ul>

              <h3>We Want to Hear From You</h3>
              <p>Email your information to <a href="mailto:jobs@computesoftware.com">jobs@computesoftware.com</a> with “Senior Software Engineer” in the subject line.</p>
            </div>
          </div>
          <div>
            <p>Don’t see a job posting and think you have what it takes?<br/>
            Email us at <a href="mailto:jobs@computesoftware.com">jobs@computesoftware.com</a></p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
